import { CircularProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { Dispatch, JSX, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CrossCircleIcon from '../../../../assets/icons/cross-circle.svg?react';
import ImageCircleIcon from '../../../../assets/icons/image-circle.svg?react';
import { DataTestId, ObjectUploadStatus, OperationImageType } from '../../../../types';
import { formatTimestamp } from '../../../../utilities';
import { UserTimezoneContext } from '../../../contexts';
import { OperationImageModal } from '../operation-image-modal';

interface SubOperationImageProps {
  image: OperationImageType;
  imageUrl?: string;
  loading?: boolean;
  hasError?: boolean;
  setOpenOperationImage?: Dispatch<SetStateAction<boolean>>;
}

interface SubOperationImagePlaceholderProps extends DataTestId {
  icon: JSX.Element;
  description: string;
}

interface SubOperationImageThumbnailProps extends SubOperationImageProps {
  deviceId: string;
  mainOperationId: string;
}

const SubOperationImagePlaceholder = ({
  icon,
  description,
  'data-testid': dataTestId
}: SubOperationImagePlaceholderProps): JSX.Element => {
  return (
    <div className="sub-operation-image-thumbnail__image" data-testid={dataTestId}>
      <div className="sub-operation-image-thumbnail__icon-container">{icon}</div>
      <span className="sub-operation-image-thumbnail__description">{description}</span>
    </div>
  );
};

const SubOperationImage = ({ image, imageUrl, loading, hasError }: SubOperationImageProps): JSX.Element => {
  const { t } = useTranslation();

  if (image.status === ObjectUploadStatus.UploadDone) {
    if (loading) {
      return (
        <SubOperationImagePlaceholder
          icon={<CircularProgress size={24} />}
          description={t('operationsPage.operationDetails.images.loading')}
          data-testid="sub-operation-image-placeholder-loading"
        />
      );
    }

    if (hasError) {
      return (
        <SubOperationImagePlaceholder
          icon={<CrossCircleIcon className="sub-operation-image-thumbnail__icon-cross-circle" />}
          description={t('operationsPage.operationDetails.images.error')}
          data-testid="sub-operation-image-placeholder-error"
        />
      );
    }

    if (imageUrl) {
      return <img className="sub-operation-image-thumbnail__image" data-testid="sub-operation-image" src={imageUrl} />;
    }
  }

  if (
    image.status === ObjectUploadStatus.UploadFailed ||
    image.status === ObjectUploadStatus.UploadFailedNotFound ||
    image.status === ObjectUploadStatus.UploadRequestFailed
  ) {
    return (
      <SubOperationImagePlaceholder
        icon={<CrossCircleIcon className="sub-operation-image-thumbnail__icon-cross-circle" />}
        description={t('operationsPage.operationDetails.images.failed')}
        data-testid="sub-operation-image-placeholder-failed"
      />
    );
  }

  return (
    <SubOperationImagePlaceholder
      icon={<ImageCircleIcon className="sub-operation-image-thumbnail__icon-image-circle" />}
      description={t('operationsPage.operationDetails.images.request')}
      data-testid="sub-operation-image-placeholder-request"
    />
  );
};

const SubOperationImageThumbnailInformation = ({ image }: { image: OperationImageType }): JSX.Element => {
  const { userTimezone } = useContext(UserTimezoneContext);
  const generateImageDisplayName = (image: OperationImageType): string => {
    return `${image.type}-${image.sequence}-${image.visualType}`;
  };
  return (
    <div className="sub-operation-image-thumbnail__information">
      <p className="sub-operation-image-thumbnail__file-name">{generateImageDisplayName(image)}</p>
      <p className="sub-operation-image-thumbnail__date">
        {formatTimestamp(image.recordedAt, userTimezone, undefined, DateTime.DATE_FULL)}
      </p>
      <p className="sub-operation-image-thumbnail__time">
        {formatTimestamp(image.recordedAt, userTimezone, undefined, DateTime.TIME_24_WITH_SECONDS)}
      </p>
    </div>
  );
};

export const SubOperationImageThumbnail = ({
  image,
  imageUrl,
  loading,
  hasError,
  deviceId,
  mainOperationId
}: SubOperationImageThumbnailProps): JSX.Element => {
  const [openOperationImage, setOpenOperationImage] = useState<boolean>(false);

  return (
    <div className="sub-operation-image-thumbnail" data-testid="sub-operation-image-thumbnail">
      <button className="sub-operation-image-thumbnail__button" onClick={() => setOpenOperationImage(true)}>
        <div className="sub-operation-image-thumbnail__image-container">
          <SubOperationImage image={image} imageUrl={imageUrl} loading={loading} hasError={hasError} />
        </div>
        <SubOperationImageThumbnailInformation image={image} />
      </button>
      <OperationImageModal
        operationId={mainOperationId}
        image={image}
        open={openOperationImage}
        deviceId={deviceId}
        setOpenOperationImage={setOpenOperationImage}
      />
    </div>
  );
};
