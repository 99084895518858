import { isFinite, isNumber, round } from 'lodash';

import i18n from '../../../../../i18n';
import { formatTimestamp, getNonNullishDisplayValue } from '../../../../../utilities';

export const contextualizeConnectivityStatusDisplayValue = (value?: number | null): string => {
  switch (value) {
    case 0:
      return i18n.t('deviceDetailsPage.deviceHealth.connectivityState.offline');
    case 1:
      return i18n.t('deviceDetailsPage.deviceHealth.connectivityState.heartbeatOnly');
    case 2:
      return i18n.t('deviceDetailsPage.deviceHealth.connectivityState.online');
    default:
      return i18n.t('deviceDetailsPage.deviceHealth.unknown');
  }
};

export const contextualizeSafetyTriggeredDisplayValue = (value?: number | null): string => {
  switch (value) {
    case 0:
      return i18n.t('deviceDetailsPage.deviceHealth.safetyTriggered.notTriggered');
    case 1:
      return i18n.t('deviceDetailsPage.deviceHealth.safetyTriggered.triggered');
    default:
      return i18n.t('deviceDetailsPage.deviceHealth.unknown');
  }
};

export const calculateUsagePercentage = (usage?: number | null, total?: number | null): number => {
  if (isNumber(usage) && isNumber(total)) {
    const calculatedValue = (usage / total) * 100;
    return isNaN(calculatedValue) || !isFinite(calculatedValue) ? 0 : round(calculatedValue, 0);
  }
  return 0;
};

export const generateDisplayTimestamp = (time?: string | null, userTimezone?: string): string => {
  return i18n.t('deviceDetailsPage.deviceHealth.timestamp', {
    timestamp: `${time ? formatTimestamp(time, userTimezone) : getNonNullishDisplayValue(time)}`
  });
};
