import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GetDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs';
import { MUTATION_COLLECT_DEVICE_METRICS } from '../../../../services/mutations';
import { SnackbarMessageType } from '../../../../types';
import {
  constructSnackbarMessage,
  formatTimestamp,
  getDeviceMeasurementValue,
  mapDeviceStateToStatusIcon
} from '../../../../utilities';
import {
  InformationBlock,
  RSActionLink,
  RSCard,
  RSCardDefaultTitle,
  RSLinearProgress,
  RSTooltip
} from '../../../5-elements';
import { UserTimezoneContext } from '../../../contexts';
import {
  DeviceStatus,
  calculateUsagePercentage,
  contextualizeConnectivityStatusDisplayValue,
  contextualizeSafetyTriggeredDisplayValue,
  generateDisplayTimestamp,
  mapConnectivityToStatusIcon,
  mapMeasurementStatusToStatusIcon,
  mapSafetyTriggeredToStatusIcon,
  mapUsageStatusToProgressBarColor
} from '../device-status';

interface DeviceHealthCardsProps {
  deviceId: string;
  canUserEditDevice?: boolean;
  deviceLastState?: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceLastState'];
  deviceMeasurementValues?: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceMeasurementValues'];
}

export const DeviceHealthCards = ({
  deviceId,
  canUserEditDevice,
  deviceLastState,
  deviceMeasurementValues = []
}: DeviceHealthCardsProps): JSX.Element => {
  const { userTimezone } = useContext(UserTimezoneContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const sendMessageToSnackbar = (...args: SnackbarMessageType): void => {
    enqueueSnackbar(constructSnackbarMessage(...args));
  };

  const [collectDeviceMetrics, { loading: loadingCollectDeviceMetrics }] = useMutation(
    MUTATION_COLLECT_DEVICE_METRICS,
    {
      context: { timeout: graphqlApiConfig.mutationTimeout },
      onError: (error) => {
        sendMessageToSnackbar(
          t('deviceDetailsPage.deviceHealth.connectivityState.snackbarMessages.reCheckConnectivityError'),
          undefined,
          error.message || error.name,
          'error'
        );
      },
      onCompleted: () => {
        sendMessageToSnackbar(
          t('deviceDetailsPage.deviceHealth.connectivityState.snackbarMessages.reCheckConnectivityOK'),
          undefined,
          undefined,
          'success'
        );
      }
    }
  );

  const handleCollectDeviceMetrics = (): void => {
    collectDeviceMetrics({
      variables: {
        deviceIds: [deviceId]
      }
    });
  };

  const safetyTriggeredMeasurement = getDeviceMeasurementValue('safety_triggered', deviceMeasurementValues);
  const connectivityMeasurement = getDeviceMeasurementValue('is_online', deviceMeasurementValues);
  const memoryUsageMeasurement = getDeviceMeasurementValue('memory_usage', deviceMeasurementValues);
  const memoryTotalMeasurement = getDeviceMeasurementValue('memory_total', deviceMeasurementValues);
  const storageUsageMeasurement = getDeviceMeasurementValue('storage_usage', deviceMeasurementValues);
  const storageTotalMeasurement = getDeviceMeasurementValue('storage_total', deviceMeasurementValues);
  const queueLengthMeasurement = getDeviceMeasurementValue('remote_queue_length', deviceMeasurementValues);

  const safetyTriggeredValue = contextualizeSafetyTriggeredDisplayValue(safetyTriggeredMeasurement?.value);
  const connectivityStateValue = contextualizeConnectivityStatusDisplayValue(connectivityMeasurement?.value);
  const memoryUsageValue = calculateUsagePercentage(memoryUsageMeasurement?.value, memoryTotalMeasurement?.value);
  const storageUsageValue = calculateUsagePercentage(storageUsageMeasurement?.value, storageTotalMeasurement?.value);

  return (
    <div className="device-health-cards">
      <RSCard
        dataTestId="device-health-card-device-state"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.deviceState.title')} />}
      >
        <InformationBlock
          value={
            <DeviceStatus
              statusIconVariant={mapDeviceStateToStatusIcon(deviceLastState?.deviceStatus)}
              statusValue={deviceLastState?.deviceStatus}
              stateName={deviceLastState?.stateName}
            />
          }
          subValue={generateDisplayTimestamp(deviceLastState?.updatedAt, userTimezone)}
        />
      </RSCard>
      <RSCard
        dataTestId="device-health-card-safety-triggered"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.safetyTriggered.title')} />}
      >
        <InformationBlock
          value={
            <DeviceStatus
              statusIconVariant={mapSafetyTriggeredToStatusIcon(safetyTriggeredMeasurement?.value)}
              statusValue={safetyTriggeredValue}
            />
          }
          subValue={generateDisplayTimestamp(safetyTriggeredMeasurement?.updatedAt, userTimezone)}
        />
      </RSCard>
      <RSCard
        dataTestId="device-health-card-connectivity-state"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.connectivityState.title')} />}
      >
        <InformationBlock
          value={
            <DeviceStatus
              statusIconVariant={mapConnectivityToStatusIcon(connectivityMeasurement?.value)}
              statusValue={connectivityStateValue}
            />
          }
          subValue={generateDisplayTimestamp(connectivityMeasurement?.updatedAt, userTimezone)}
        />
        {canUserEditDevice && (
          <InformationBlock
            label=""
            value={
              <span className="device-health-card-connectivity-state__recheck-connectivity">
                <RSActionLink
                  data-testid="device-health-card-connectivity-state-recheck"
                  disabled={loadingCollectDeviceMetrics}
                  handleClick={() => {
                    handleCollectDeviceMetrics();
                  }}
                >
                  {t('deviceDetailsPage.deviceHealth.connectivityState.reCheckConnectivity')}
                </RSActionLink>
              </span>
            }
          />
        )}
      </RSCard>
      <RSCard
        dataTestId="device-health-card-system-health"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.measurements.title')} />}
      >
        <div className="device-health-cards__measurements">
          <RSTooltip
            title={
              <div className="device-health-cards__measurement-tooltip">
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.memory', {
                    usage: memoryUsageMeasurement?.value,
                    total: memoryTotalMeasurement?.value
                  })}
                </span>
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.since', {
                    timestamp: formatTimestamp(memoryUsageMeasurement?.updatedAt || '', userTimezone)
                  })}
                </span>
              </div>
            }
            placement="right"
          >
            <div className="device-health-cards__measurement">
              <DeviceStatus
                statusIconVariant={mapMeasurementStatusToStatusIcon(memoryUsageMeasurement?.status)}
                statusValue={t('deviceDetailsPage.deviceHealth.measurements.memory')}
                sizeVariant="small"
              />
              <RSLinearProgress
                data-testid="device-health-memory-usage-progress-bar"
                value={memoryUsageValue}
                labelTextSize="large"
                widthVariant="long"
                color={mapUsageStatusToProgressBarColor(memoryUsageMeasurement?.status)}
              />
            </div>
          </RSTooltip>
          <RSTooltip
            title={
              <div className="device-health-cards__measurement-tooltip">
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.storage', {
                    usage: storageUsageMeasurement?.value,
                    total: storageTotalMeasurement?.value
                  })}
                </span>
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.since', {
                    timestamp: formatTimestamp(storageUsageMeasurement?.updatedAt || '', userTimezone)
                  })}
                </span>
              </div>
            }
            placement="right"
          >
            <div className="device-health-cards__measurement">
              <DeviceStatus
                statusIconVariant={mapMeasurementStatusToStatusIcon(storageUsageMeasurement?.status)}
                statusValue={t('deviceDetailsPage.deviceHealth.measurements.storage')}
                sizeVariant="small"
              />
              <RSLinearProgress
                data-testid="device-health-storage-usage-progress-bar"
                value={storageUsageValue}
                labelTextSize="large"
                widthVariant="long"
                color={mapUsageStatusToProgressBarColor(storageUsageMeasurement?.status)}
              />
            </div>
          </RSTooltip>
          <RSTooltip
            title={
              <div className="device-health-cards__measurement-tooltip">
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.since', {
                    timestamp: formatTimestamp(queueLengthMeasurement?.updatedAt || '')
                  })}
                </span>
              </div>
            }
            placement="right"
          >
            <div className="device-health-cards__measurement">
              <DeviceStatus
                statusIconVariant={mapMeasurementStatusToStatusIcon(queueLengthMeasurement?.status)}
                statusValue={t('deviceDetailsPage.deviceHealth.measurements.queueLength')}
                sizeVariant="small"
              />
              <span>{queueLengthMeasurement?.value}</span>
            </div>
          </RSTooltip>
        </div>
      </RSCard>
    </div>
  );
};
