import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerCountBlocks, CustomerCountBlocksProps } from './customer-count-blocks';
import { CustomerProgramsTable } from './customer-programs-table';
import { GetCustomerDetailsQuery } from '../../../../__generated__/graphql';
import ArrowLeftIcon from '../../../../assets/icons/arrow-left.svg?react';
import { Flatten } from '../../../../types';
import { formatTimestamp } from '../../../../utilities';
import { InformationBlock, InternalLink } from '../../../5-elements';
import { UserTimezoneContext } from '../../../contexts';

interface CustomerDetailsPageProps {
  title: string;
  counts: CustomerCountBlocksProps;
  createdAt: string;
  updatedAt: string;
  programs: Flatten<GetCustomerDetailsQuery['customers']>['programs'];
  showBackToCustomerOverview?: boolean;
}

export const CustomerDetailsSidePanel = ({
  title,
  counts,
  createdAt,
  updatedAt,
  programs,
  showBackToCustomerOverview
}: CustomerDetailsPageProps): JSX.Element => {
  const { t } = useTranslation();
  const { userTimezone } = useContext(UserTimezoneContext);

  return (
    <section className="customer-details-side-panel" data-testid="customer-details-side-panel">
      <div className="customer-details-side-panel__title">
        {showBackToCustomerOverview && (
          <InternalLink
            to="/portal/customers"
            icon={<ArrowLeftIcon />}
            text={t('customerDetailsPage.sidePanel.backLinkText')}
          />
        )}
        <h1>{title}</h1>
      </div>
      <div className="customer-details-side-panel__customer-information">
        <div className="customer-details-side-panel__customer-statistics">
          <CustomerCountBlocks devices={counts.devices} sites={counts.sites} programs={counts.programs} />
          <div className="customer-details-side-panel__dates" data-testid="customer-details-side-panel-dates">
            <InformationBlock
              label={t('customerDetailsPage.sidePanel.dates.createdAt')}
              subValue={formatTimestamp(createdAt, userTimezone)}
            />
            <InformationBlock
              label={t('customerDetailsPage.sidePanel.dates.updatedAt')}
              subValue={formatTimestamp(updatedAt, userTimezone)}
            />
          </div>
        </div>
        <CustomerProgramsTable programs={programs} />
      </div>
    </section>
  );
};
